<template>
  <div class="page-main" :style="{ '--themeColor': $store.state.themeInfo.themeColor }">
    <div v-if="containsMiceLink" class="page-content-wrapper">
      <div :style="{ paddingBottom: $store.state.themeInfo.contactType === 0 ? '0px' : '50PX' }" class="page-content">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
      </div>
      <div class="menu-tab-bar" v-show="$store.state.themeInfo.contactType !== 0">
        <!-- <div class="line" ref="line"></div> -->
        <div v-for="(item, index) in pageList" :key="index" class="item" @click="onChange(index)" ref="item">
          <van-icon :name="item.icon" class="icon" v-if="item.iconType === 1 && !item.icon.startsWith('image')" />
          <img :src="require('@/assets/images/blackIcon/' + item.icon.slice(6) + '.png')" alt="" v-if="item.iconType === 1 && item.icon.startsWith('image')">
          <img :src="index === active ? item.actived : item.icon" alt="" v-if="item.iconType === 0">
          {{ item.name }}
        </div>
      </div>
      <float-menu :innerPage="true" @goHome="onChange(0)" />
    </div>
    <no-page v-else/>
  </div>
</template>

<script>
import floatMenu from '@/components/floatMenu/index'
import noPage from '@/components/noPage/noPage'
// import bus from '@/utils/bus'
// import { Dialog } from 'vant' 
import wx from '@/utils/wx'
export default {
  name: 'basicLayout',
  components: { noPage, floatMenu },
  data() {
    return {
      active: 0,
      containsMiceLink: false,
      miceLink: '',
      pageList: [],
      length: 0,
      selfPageCount: [],
      code: ''
      // icons: {}
    }
  },
  methods: {
    // dealIcon(icon) {
    //   return this.icons[icon.slice(6)]
    // },
    onChange(index, flag = false) {
      this.$nextTick(() => {
        // this.$refs.line.style.width = parseFloat(getComputedStyle(this.$refs.item[index]).width) - 30 + 'px'
        // let offset = 15
        // for (let i = 0; i < index; i ++) {
        //   offset = offset + parseFloat(getComputedStyle(this.$refs.item[i]).width)
        // }
        // this.$refs.line.style.transform = `translateX(${offset}px)`
        this.active = index
        if (!flag) {
          this.animated(index)
        } else {
          this.initItem(index)
        }
        if (this.pageList[index].type === 0) {
          window.location.href = this.pageList[index].link
        } else {
          this.$router.push(this.pageList[index].link)
        }
      })
    },
    initItem(index) {
      this.$refs.item[index].classList.add('nav-active')
      this.$refs.item.forEach(item => {
        item.addEventListener('animationend', () => {
          item.classList.remove('animat-click')
        })
      })
    },
    animated(index) {
      this.$refs.item[index].classList.add('animat-click')
      this.$refs.item.forEach(item => {
        item.classList.remove('nav-active')
      })
      this.$refs.item[index].classList.add('nav-active')
    },
    getPageList() {
      this.pageList = [
        {
          name: '首页',
          icon: require('@/assets/images/icon/home.png'),
          link: '/index/' + this.miceLink,
          iconType: 0,
          actived: require('@/assets/images/icon/home-act.png'),
          type: 1
        },
        {
          name: '日程',
          icon: require('@/assets/images/icon/date.png'),
          link: '/arrangeDay/' + this.miceLink,
          iconType: 0,
          actived: require('@/assets/images/icon/date-act.png'),
          type: 1
        },
        {
          name: '导航',
          icon: require('@/assets/images/icon/map.png'),
          link: '/map/' + this.miceLink,
          iconType: 0,
          actived: require('@/assets/images/icon/map-act.png'),
          type: 1
        },
        {
          name: '我的',
          icon: require('@/assets/images/icon/user.png'),
          link: '/user/' + this.miceLink,
          iconType: 0,
          actived: require('@/assets/images/icon/user-act.png'),
          type: 1
        }
      ]
      this.selectedNav()
    },
    async configShareInfo() {
      let ua = window.navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        let result = await this.$http.get(this.$baseURL.index.getShareInfo() + '?miceLink=' + this.miceLink)
        const shareInfo = {
          shareTitle: result.shareTitle || result.miceName,
          shareContent: result.shareContent || result.miceName,
          sharePosters: result.sharePosters || result.posters
        }
        wx.config(shareInfo)
      }
    },
    getWxCode() {
      window.location.href = this.$baseURL.weixin.getWxCode(window.location.href.split('#')[0])
    },
    // requireImage() {
    //   for (let i = 1; i <= 71; i ++) {
    //     this.icons[i + ''] = require(`../assets/images/blackIcon/${i}.png`)
    //   }
    // },
    selectedNav(flag = true) {
      this.pageList.forEach((item, index) => {
        if (this.$route.path.includes(item.link)) {
          this.onChange(index, flag)
        }
      })
    }
  },
  created() {
    const user = this.$utils.getCookie('user')
    // const user = {
    //   openid: 'ogQs3w39cHZQmueTFbwcLD5lgmHs'
    // }
    // const user = {
    //   openid: 'ogQs3w2uKXOWiqyr1aEWcEYTuMg4'
    // }
    // const user = {
    //   openid: 'op9Vd6SwWK6X2wxoMLhCVNetoiVU'
    // }

    this.$utils.setCookie('user', user)
    if (!user || !user.openid) {
      if (!this.$route.query.code) {
        window.location.href = this.$baseURL.weixin.getWxCode(window.location.href.split('#')[0])
        this.signOk = true
        return
      } else {
        this.signOk = true
        this.code = this.$route.query.code
        this.$http.postParams(this.$baseURL.weixin.userSign(), { code: this.code }, 1).then((res) => {
          if (res.code === 200) {
            this.$utils.setCookie('user', JSON.stringify(res.data ? res.data : {}))
            this.$store.commit('userInfo/SET_USER', res.data)
          }
        })
      }
    } else {
      this.signOk = true
      this.$store.commit('userInfo/SET_USER', user)
    }
    const isSameOne = this.$utils.getSessionStorage('sesionFlag')
    if (!isSameOne) {
      this.$utils.setSessionStorage('sesionFlag', 'true')
      const data = {
        openId: typeof(user) === 'string' ? 'normal-persoon' : (user === null ? 'normal-person' : user.openid),
        miceLink: this.$store.state.miceInfo.miceLink
      }
      this.$http.post(this.$baseURL.index.recordVisit(), data, 1).then((res) => {
        console.log(res)
      })
    }
  },
  mounted() {
    // console.log(111)
    if (this.$route.params.miceLink) {
      this.miceLink = this.$route.params.miceLink
      this.$store.commit('miceInfo/SET_MICE_LINK', this.miceLink)
      this.containsMiceLink = true
      this.getPageList()
      this.configShareInfo()
      if (this.$route.query.code) {
        this.code = this.$route.query.code
        this.$http.postParams(this.$baseURL.weixin.userSign(), { code: this.code }, 1).then(() => {
          // this.getPageList()
        })
      } else {
        // this.getWxCode()
      }
    } else {
      this.containsMiceLink = false
    }
  }
}
</script>

<style lang="less" scoped>
@themeColor: var(--themeColor);
.page-main {
  height: 100vh;
}
.navigator{
  background: #cae9ff;
  position: fixed;
  color: #8a8a8a;
  /deep/ .van-tabbar-item-active{
    background: #cae9ff;
  }
}
.tabbar{
  // position: fixed;
  bottom: 0;
}
.page-content-wrapper {
  height: 100%;
}
.page-content{
  overflow-y: auto;
  height: 100%;
  background: rgb(242, 248, 252);
}
.menu-tab-bar{
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  height: 50PX;
  width: 100%;
  background: rgba(255, 255, 255, .97);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  -o-backdroop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  font-size: 12PX;
  box-shadow: 0px -3px 5px -3px rgba(0,0,0,.05);
  z-index: 10;
  .item{
    position: relative;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding-bottom: 4px;
    transition: .3s;
    overflow: hidden;
    color: #8e8e8e;
    .icon{
      margin-bottom: 4px;
      font-size: 18PX;
    }
    img{
      height: 18PX;
      width: 18PX;
      margin-bottom: 4px;
    }
  }
  .animat-click{
    &::before{
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: @themeColor;
      height: 140%;
      width: 60%;
      border-radius: 50%;
      z-index: -1;
      animation: circl .3s linear 1;
      opacity: 0;
    }
  }
  @keyframes circl{
    0%{
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
    }
    50%{
      transform: translate(-50%, -50%) scale(1.4);
      opacity: .09;
    }
    100%{
      transform: translate(-50%, -50%) scale(1.8);
      opacity: 0;
    }
  }
  .nav-active{
    color: #333;
    &::after{
      content: '';
      position: absolute;
      height: 15PX;
      width: 15PX;
      left: 50%;
      top: 50%;
      transform: translate(-70%, -120%);
      background: linear-gradient(to right, @themeColor 10%, #fff 90%);
      border-radius: 50%;
      z-index: -1;
      // filter: blur(2px);
    }
  }
  .line{
    position: absolute;
    transition: .3s;
    top: 0;
    left: 0;
    height: 3px;
    background: @themeColor;
    transform-origin: center;
  }
}
</style>
